import React, { useState } from "react";
import Header from "./header";
import Search from "./search";
import Results from "./results";
import FooterHeader from "./footer";

const Main = () => {

  const [resetPagination, setResetPagination] = useState(false);

  return (
    <div className="wrapper">
      <Header />
      <Search setResetPagination={setResetPagination} />
      <Results resetPagination={resetPagination} setResetPagination={setResetPagination} />
      <FooterHeader />
    </div>
  );
};

export default Main;
