import * as R from "ramda";
import queryString from "query-string";
import { FILTER_FIELDS } from "../landing/search";

export const resultsParams = (search,initial) => {
  let queryParams = parseQuery(search);

  queryParams = R.assoc("pageSize", Number(queryParams.page_size), queryParams);
  queryParams = (initial) ?
    R.assoc("page", Number(1), queryParams)
    :R.assoc("page", Number(queryParams.page) + 1, queryParams);

  queryParams = R.dissoc("page_size", queryParams);

  if (queryParams[FILTER_FIELDS.COUNTRY]) {
    queryParams = R.assoc(
      FILTER_FIELDS.COUNTRY,
      [queryParams[FILTER_FIELDS.COUNTRY]],
      queryParams
    );
  }

  if (queryParams[FILTER_FIELDS.DONOR]) {
    queryParams = R.assoc(
      FILTER_FIELDS.DONOR,
      [queryParams[FILTER_FIELDS.DONOR]],
      queryParams
    );
  }

  if (queryParams[FILTER_FIELDS.ORGANIZATION]) {
    queryParams = R.assoc(
      FILTER_FIELDS.ORGANIZATION,
      [queryParams[FILTER_FIELDS.ORGANIZATION]],
      queryParams
    );
  }

  if (queryParams[FILTER_FIELDS.CATEGORY]) {
    queryParams = R.assoc(
      FILTER_FIELDS.CATEGORY,
      [queryParams[FILTER_FIELDS.CATEGORY]],
      queryParams
    );
  }

  if (queryParams[FILTER_FIELDS.REPORT]) {
    queryParams = R.assoc(
      FILTER_FIELDS.REPORT,
      [queryParams[FILTER_FIELDS.REPORT]],
      queryParams
    );
  }

  if (queryParams[FILTER_FIELDS.YEAR]) {
    queryParams = R.assoc(
      FILTER_FIELDS.YEAR,
      [queryParams[FILTER_FIELDS.YEAR]],
      queryParams
    );
  }


  const orderByVal = queryParams["orderBy"]
  if (orderByVal) {
    queryParams = R.assoc(
      "orderBy",
      !Array.isArray(orderByVal) ? [orderByVal] : orderByVal,
      queryParams
    );
  }

  return queryParams;
};

export const isEmptyFilters = search => {
  let queryParams = parseQuery(search);
  queryParams = R.dissoc("page_size", queryParams);
  queryParams = R.dissoc("page", queryParams);
  queryParams = R.dissoc("orderBy", queryParams);

  return R.isEmpty(queryParams);
};

export const capitalize = text => {
  return R.toLower(text).replace(/\b\w/g, l => l.toUpperCase());
};

export const removeQuotes = text => {
  return text.replace(/["']/g, "");
}

export const parseQuery = (search) =>  {
  let queryParams = queryString.parse(search);

  const arrayFields = [
    "orderBy"
  ];
  arrayFields.forEach(field => {
    if (queryParams[field] && queryParams[field].includes(',')) {
      queryParams[field] = queryParams[field].split(',');
    }
  });

  return queryParams;
}
