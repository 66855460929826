import React, { Component } from "react";
import { Footer, Link } from "@wfp/ui";

const texts = {
  info_1: "View the web-based Annual Country Reports",
  info_2: "For reports prior to 2008, please contact ",
  financial_info_1: " For any financial query, please contact",
  financial_mail: "wfp.finance.acr@wfp.org",
  mail: "wfp.annualreportqueries@wfp.org"
};

class FooterHeader extends Component {
  render() {
    return (
      <Footer
        className="footer"
        metaContent="2025 © World Food Programme"
        mobilePageWidth="full"
        pageWidth="lg"
      >
        <div className="wfp--footer-info">
          <div className="wfp--footer-info__item">
            <p className="wfp--footer-label">
              <Link href="https://www.wfp.org/annual-country-reports-2024">{texts.info_1}</Link>
            </p>
          </div>
          <div className="wfp--footer-info__item">
            <p className="buttonContainer">
              {texts.info_2}&nbsp;
              <Link href={`mailto:${texts.mail}`} className="footer-mail">
                <strong>{texts.mail}</strong>
              </Link>
            </p>
          </div>
          <div className="wfp--footer-info__item">
            <p className="buttonContainer">
              {texts.financial_info_1}&nbsp;
              <Link href={`mailto:${texts.financial_mail}`} className="footer-mail">
                <strong>{texts.financial_mail}</strong>
              </Link>
            </p>
          </div>
        </div>
      </Footer>
    );
  }
}

export default FooterHeader;
